<template>
	<div class="doctor_management">
		<el-row class="bread_crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/RoleManagement' }">系统管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/RoleManagement' }">权限管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/AuthorizationDetail' }">授权</el-breadcrumb-item>
			</el-breadcrumb>
		</el-row>
	<div class="public_bg">
		<!-- header -->
		<!-- <v-header-datail changeState="详情页面" :routerPaths="paths"></v-header-datail> -->
		<el-row style="margin-top: 20px;">
			<el-button type="primary" @click="addRole">新增</el-button>
		</el-row>
		<el-row style="margin-top:20px;width: 500px;height: 100%;">
			<el-tree  :data="RelationData" node-key="menuCode" default-expand-all :expand-on-click-node="false">
				<span class="custom-tree-node" slot-scope="{ node, data }">
					<span>{{ node.label }}</span>
					<span >
						<el-button v-if="!data.children" type="text" size="mini" @click="() => append(data)">
							设置权限
						</el-button>
						<el-button type="text" size="mini" @click="() => remove(node, data)">
							删除
						</el-button>
					</span>
				</span>
			</el-tree>
		</el-row>
		<!-- 功能授权模态框 -->
		<el-dialog title="功能授权" :visible.sync="AuthorityDialog" width="40%">
			<div>
				<tree-transfer filter :title="['未授权', '已授权']" :button-text="['取消','授权']" :from_data='fromData' :to_data='toData'
				@addBtn='changedata'  @removeBtn='changedata'></tree-transfer>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="" @click="AuthorityDialog = false">取消</el-button>
                <el-button type="primary" @click="insertSure">确认</el-button>
			</span>
		</el-dialog>
		</div>
	</div>
</template>

<script>
	import treeTransfer from 'el-tree-transfer'
	import bus from '../../components/bus';
	import vHeaderDatail from "../common/HeaderDatail.vue"; //引入详情页返回组件
	export default {
		components: {
			treeTransfer,
			vHeaderDatail
		},
		data() {
			return {
				AuthorityDialog:false,
				paths: '/RoleManagement',
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				RelationData: [],
				fromData: [], //授权原数据
				toData: [], //授权目标数据
			};
		},
		methods: {
			arrayToList(tree, arr=[]){
				tree.map((val)=>{
					let temp = JSON.parse(JSON.stringify(val))
					delete temp.children
					arr.push(temp)
					if(val.children){
					this.arrayToList(val.children, arr)
					}
				})
				return arr
			},
			//更改授权穿梭框
			changedata(fromdata,todata,obj){
				this.addList = [];
				this.delList =[];
				this.arrayToList(todata, this.addList);
				this.arrayToList(fromdata, this.delList);
			},
			//授权确定
			async QueryRoleMenuRelationUpdate() {
				let info = {
					insertData: this.addList,
			        updateData: this.delList
				}
				let res = await this.$Http.RoleMenuRelationUpdate(info);
				if (res.code == '200') {
					this.$message.success(res.message);
					this.AuthorityDialog = false;
					bus.$emit('AuthorityFlag', 1);
					this.QueryRoleMenuRelation(this.roleId)
				} else {
					this.$message.error(res.message);
				}
			},
			// 授权确定
			insertSure() {
				this.QueryRoleMenuRelationUpdate();
			},
			addRole(){
				this.AuthorityDialog = true;
				this.QueryRoleMenuRelation(this.roleId)
			},
			// 
			append(data) {
				console.log(data)
				this.$router.push({
					path: '/AuthorizationDetail',
					query: {
						roleId:data.code,
						menuId:data.id
					}
				})
			},
			//删除菜单授权
			async QueryRoleMenuDelete() {
				let info = {
					id: this.MenuDelete.id,
					label: this.MenuDelete.label,
					no: this.MenuDelete.no,
					code: this.MenuDelete.code
                }
				let res = await this.$Http.RoleMenuDelete(info);
				if (res.code == '200') {
					this.$message.success(res.message);
					bus.$emit('AuthorityFlag', 1);
					this.QueryRoleMenuRelation(this.roleId)
				} else {
					this.$message.error(res.message);
				}
			},
            remove(node, data) {
				this.MenuDelete=data;
				this.QueryRoleMenuDelete();
			},
			//授权数据
			async QueryRoleMenuRelation(roleId) {
				let info = {
					roleId: roleId
				}
				let res = await this.$Http.RoleMenuRelation(info);
				if (res.code == '200') {
					this.RelationData = res.data.toData ? JSON.parse(JSON.stringify(res.data.toData)) : [];
					this.fromData = res.data.fromData;
					this.toData = res.data.toData;
				} else {
					this.$message.error(res.message);
				}
			},
		},
		mounted() {
			this.roleId = localStorage.getItem("roleId");
			this.QueryRoleMenuRelation(this.roleId)
		}
	};
</script>

<style>
	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	  }
</style>
